<template>
	<section class="section--padding text-center text-md-start history">
		<v-container class="container--narrow">
			<h3 class="mb-10 d-md-none">О нас</h3>
			<v-row justify="space-between">
				<v-col cols="12" md="6" class="pr-md-10 pr-lg-0 d-flex justify-center justify-md-start">
					<animated-img :src="require('@/assets/home/history/h1.jpg')" alt="Candies"
								  :reversed="true"
								  class="mb-8"/>
				</v-col>
				<v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
					<div class="pt-md-4">
						<h3 class="mb-8 d-none d-md-block">О нас</h3>
						<p class="limited">
							Наша история началась в 2008 году в Санкт-Петербурге,
							где был создан первый ресторан Счастье, и обрела
							продолжение в Париже, в лобби отеля COSTES, где в 2010
							шеф-кондитер Лоран Морено презентовал коробочку своих
							десертов Игорю Белявскому, что стремительно воплотилось
							в совместную работу над проектом СЧАСТЬЕ.
						</p>
						<p class="limited">
							Первыми результатами стали оригинальные десерты ресторанов
							и три кубика пралине со вкусами: имбирь, грильяж в молочном шоколаде,
							а также кунжут с морской солью в горьком шоколаде, которые Лоран сделал
							для того, чтобы гости могли взять их с собой домой для самых близких.
						</p>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    import AnimatedImg from "@/components/animated-img";

    export default {
        name: "history",
        components: {
            AnimatedImg
        },
    }
</script>

<style lang="scss">
	.history {
		background: $primary-50;
	}
</style>